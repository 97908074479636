import { useState } from 'react';
import { SimpleForm, FileInput, FileField, Button, SaveButton, useRedirect, useGetList } from 'react-admin';
import { Container, CssBaseline, FormControlLabel, FormGroup, Paper, Switch, CircularProgress } from '@mui/material';
import { UserImportList } from './UserImportList';


import awsmobile from '../aws-export';
// import { Auth } from 'aws-amplify';
import inMemoryJwt from '../Component/authProvider/inMemoryJwt';

import Papa from 'papaparse';

const apiUrl = awsmobile.aws_backend_api_url;

const UserImport = () => {
  const [userData, setUserData] = useState([]);
  const [importState, setImportState] = useState('init');

  const updateloadCSV = (file) => {

    setImportState('init');

    if (file && file.csvFile) {
      let count = 0;
      let validCount = 0;
      Papa.parse(file.csvFile.rawFile, {
        header: true,
        skipEmptyLines: true,
        worker: true, // Don't bog down the main thread if its a big file
        step: function (result) {
          const phoneRegex = /^\+?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
          const emailRegex = /^\b[A-Z0-9._+%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;

          if (result.data.length === 0) {
            console.log('empty row');
            return;
          }

          count++;

          if (result.data.email && result.data.application) {
            result.data.email.match(emailRegex) ? console.log('valid email') : console.log('invalid email');
            validCount++;
            console.log('valid row');
          }

          if (result.data.email && result.data.email.length > 0) {
            result.data.email = result.data.email.toLowerCase();
          }

          if (result.data.phone_number) {
            result.data.phone_number.match(phoneRegex) ? console.log('valid phone') : console.log('invalid phone');
          }

          if (result.data.application && result.data.application.length > 0) {
            result.data.groups = result.data.application.split(',').map(function (item) {
              return item.trim();
            });
          }

          if (result.data.first_name) {
            result.data.given_name = result.data.first_name;
          }

          if (result.data.last_name) {
            result.data.family_name = result.data.last_name;
          }

          if (result.data.company) {
            result.data.profile = result.data.company?.trim();
          }

          result.data.sms_mfa_enabled = result.data.sms_mfa_enabled === 'true' || result.data.sms_mfa_enabled === 'TRUE' ? true : false;

          if (validCount) {
            setImportState('csvValid');
          }

          setUserData(userData => [...userData, result.data]);
        },
        complete: function (results, file) {
          console.log('parsing complete read', count, 'records.');
        }

      });
    }
  }
  const fetchProfiles = async () => {
    // const session = await Auth.currentSession();
    const token = inMemoryJwt.getToken();

    const res = await fetch(`${apiUrl}/profiles?page=1&perPage=60`, {
      method: 'POST',
      headers: {
        Authorization: token,//session.getIdToken().getJwtToken(),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    });
    const json = await res.json();
    if (json.type === 'exception' || json.type === 'Error') {
      console.log('error', json.message);
      return [];
    }
    else {
      return json.data.map(d => d.id);
    }
  }

  const handleMyImport = async () => {
    // console.log('notify', document.getElementById('notify').checked);
    const notify = document.getElementById('notify').checked;

    let profiles = [];

    try {
      profiles = await fetchProfiles();
    }
    catch (e) {
      console.error('fetch profiles error', e);
    }

    for (let i = 0; i < userData.length; i++) {
      const user = userData[i];
      const { application, email, phone_number, given_name, family_name, ...rest } = user;

      const params = {
        application,
        email,
        phone_number,
        given_name,
        family_name,
        notify,
        ...rest
      };

      console.log('import params', params);

      // const session = await Auth.currentSession();
      const token = inMemoryJwt.getToken();
      const indexOf = (arr, q) => arr.findIndex(item => q.trim().toLowerCase() === item.trim().toLowerCase());

      const profileIndex = indexOf(profiles, params.profile);

      if (profileIndex !== -1) {
        params.profile = profiles[profileIndex];
      }

      try {

        const res = await fetch(`${apiUrl}/users`, {
          method: 'POST',
          body: JSON.stringify({ data: params }),
          headers: {
            Authorization: token,//session.getIdToken().getJwtToken(),
            'Content-Type': 'application/json',
            Accept: 'application/json',
          }
        });
        const json = await res.json();
        if (json.type === 'exception' || json.type === 'Error') {
          throw new Error(json.message)
        }
        else {
          console.log('imported data', json.data);
        }
      }
      catch (error) {
        console.log('error', error);
      }

      if (profileIndex === -1) {
        // if this is new profile, add it to profile list
        try {
          const res = await fetch(`${apiUrl}/profiles`, {
            method: 'POST',
            body: JSON.stringify({ data: { id: params.profile, name: params.profile } }),
            headers: {
              Authorization: token,//session.getIdToken().getJwtToken(),
              'Content-Type': 'application/json',
              Accept: 'application/json',
            }
          });
          const json = await res.json();
          if (json.type === 'exception' || json.type === 'Error') {
            console.error('create new company error', Error(json.message));
          }
          else {
            console.log('new company created', json.data);
            profiles.push(application.toLowerCase());
          }

        }
        catch (error) {
          console.log('error', error);
        }
      }
    }

    setImportState('importDone');
    setUserData([]);
  }

  const ImportButton = () =>
    <Button label='Import' onClick={handleMyImport} variant="contained" color="primary" startIcon={<></>} />

  const Content = () => {
    const redirect = useRedirect();
    const [checked, setChecked] = useState(true);

    const handleClick = () => {
      redirect('/users');
    }

    switch (importState) {
      case 'csvValid':
        return (
          <SimpleForm toolbar={<ImportButton label='Import Users' />} >
            <UserImportList data={userData} />
            <br /><br />
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={checked} id='notify' onChange={(e) => setChecked(e.target.checked)} />}
                label="Send an invitation email to user now" />
            </FormGroup>
            <br />
          </SimpleForm>
        )
      case 'importDone':
        return (
          <>
            <h3> Users Imported </h3>
            <Button label='View Users' onClick={handleClick} variant="contained" color="primary" startIcon={<></>}
            />
          </>
        )
      default:
        return (
          <SimpleForm onSubmit={updateloadCSV} toolbar={<SaveButton label='Load Data' icon={<></>} />}>
            <FileInput source="csvFile" label="Upload file (.csv)" accept=".csv" >
              <FileField source="src" title="title" />
            </FileInput>
            <br /><br />
            <div>
              <a href="./example.csv" download="example.csv" title="example import csv" target='_blank'>Example Import CSV file</a>
            </div>
          </SimpleForm>)
    }
  }

  return (

    <Paper elevation={1} style={{ margin: "20px", padding: "0px 20px 20px 20px", minHeight: "x" }}>
      <CssBaseline />
      <Container>

        <h1> Import Users</h1>
        <Content />

      </Container>
    </Paper>
  );
};


export default UserImport;
